<template>
  <article class="column-card">
    <figure class="column-card--image">
      <router-link
        class="column-card--link"
        :to="{ name: `contentDetails`, params: { dynamicString: '', slug: link } }">
        <div class="image-overlay"></div>
        <img :src="image" :alt="title" />
      </router-link>
    </figure>
    <h2 class="column-card--title">
      <router-link :to="{ name: `contentDetails`, params: { dynamicString: '', slug: link } }">{{ title }}</router-link>
    </h2>
    <div class="column-card--meta">
      <span class="phase-link" v-if="phase"> {{ phase }}</span>
      <span class="duration" v-if="duration">{{ duration }} MIN</span>
      <span class="event-date" v-if="date">{{ date | moment("dd / MM / YYYY") }}</span>
    </div>
  </article>
</template>

<script>
export default {
  props: ["title", "image", "link", "phase", "duration", "date"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.row {
  margin-right: -12px;
  margin-left: -12px;
}
.column-card {
  width: 100%;
  padding: 0 12px;
  padding-bottom: 40px;
  @include tablet-down {
    padding-bottom: 32px;
  }
  .column-card--image {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
    .column-card--link {
      .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        &:hover {
          background: rgba(0, 0, 0, 0.5);
        }
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .column-card--title {
    font-size: 1.25rem;
    font-weight: 400;
    color: white;
    line-height: 28px;
    padding-top: 24px;
    margin-bottom: 5px;
    &:hover {
      color: #a4aaae;
    }
  }
  .column-card--meta {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: #a4aaae;
      line-height: 22px;
      text-transform: uppercase;
    }
  }
}
</style>
