import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import i18n, {locales} from "@/i18n";

const whiteList = ["Login", "Legal", "Privacy", "Cookies", "Contact"];

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/content",
    name: "Content",
    component: () => import("../views/Content.vue"),
  },
  {
    path: "/our-journey",
    name: "Our journey",
    component: () => import("../views/Journey.vue"),
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: () => import("../views/Cookies.vue"),
  },
  {
    path: "/legal",
    name: "Legal",
    component: () => import("../views/Legal.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/Privacy.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("../views/Faq.vue"),
  },
  {
    path: "/phase/:slug",
    name: "phase",
    component: () => import("../views/phase-details.vue"),
  },
  {
    path: "/content/:slug",
    name: "contentDetails",
    component: () => import("../views/content-details.vue"),
    props: true,
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0};
  },
});

const authenticate = (to) => {
  const locale = locales.includes(to.query.locale) ? to.query.locale : process.env.VUE_APP_I18N_FALLBACK_LOCALE;
  if (!$cookies.get("access_token") && !to.query.access_token) {
    return false;
  }
  if ($cookies.get("access_token")) {
    if (to.query.access_token) {
      $cookies.set("access_token", to.query.access_token);
    }
    if (to.query.locale) {
      $cookies.set("locale", locale);
      i18n.locale = locale;
    } else {
      i18n.locale = $cookies.get("locale");
    }
  } else if (to.query.access_token) {
    $cookies.set("access_token", to.query.access_token);
    if (to.query.locale) {
      $cookies.set("locale", locale);
      i18n.locale = locale;
    }
  }
  return true;
}

router.beforeEach((to, from, next) => {
  const authCheck = authenticate(to);
  if (whiteList.includes(to.name)) {
    return next();
  }
  if (authCheck) {
    return next();
  }
  return next({name: "Login"});
});

export default router;
