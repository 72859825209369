<template>
  <div class="header-inner">
    <!-- Header Top -->
    <section class="header-top">
      <div class="container">
        <nav class="top-nav">
          <ul class="menu">
            <li v-for="(menuItem, i) in topMenu" :key="i">
              <router-link :to="menuItem.slug">{{ menuItem.title }}</router-link>
            </li>
          </ul>
<!--          TODO show language picker once more languages need to be supported-->
<!--          <LanguagePicker/>-->
        </nav>
      </div>
    </section>
    <!-- Header Bottom -->
    <section class="header-bottom">
      <b-container>
        <b-row class="holder m-0">
          <b-col class="p-0 d-flex main-logo--wrap">
            <SiteLogo/>
            <SearchBar/>
          </b-col>
          <b-col class="p-0 d-flex centered-logo--wrap" cols="2">
            <LogoMercedes/>
          </b-col>
          <b-col class="p-0 d-flex main-nav--wrap">
            <nav class="site-nav">
              <ul class="menu">
                <li v-for="(menuItem, i) in mainMenu" :key="i">
                  <router-link v-if="!menuItem.dropdown" :to="menuItem.slug">{{ $t(menuItem.title) }}</router-link>
                  <div v-else @click="() => toggleDropdown(menuItem.slug)"
                       :class="'dropdown' + (dropdown[menuItem.slug] ? ' active' : '')">
                    <p>{{ $t(menuItem.title) }}<span :class="`chevron ${dropdown[menuItem.slug] ? 'top' : 'bottom'}`"/>
                    </p>
                    <div class="dropdown-items" v-show="dropdown[menuItem.slug]">
                      <router-link v-for="(item, i) in menuItem.items" :key="i" :to="item.to">{{
                          item.title
                        }}
                      </router-link>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
            <UserAccount/>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
<script>
import LanguagePicker from "@/components/LanguagePicker.vue";
import SiteLogo from "@/components/SiteLogo.vue";
import LogoMercedes from "@/components/LogoMercedes.vue";
import SearchBar from "@/components/header/SearchBar.vue";
import UserAccount from "@/components/header/UserAccount.vue";

export default {
  name: "HeaderDesktop",
  components: {
    LanguagePicker,
    SiteLogo,
    LogoMercedes,
    SearchBar,
    UserAccount,
  },
  data() {
    return {
      topMenu: [
        {slug: "/contact", title: "Contact"},
        {slug: "/faq", title: "FAQ"},
      ],
      dropdown: {
        phase: false,
      }
    };
  },
  computed: {
    mainMenu() {
      const phases = this.$store.state.cache.data[`phases${this.$i18n.locale}`]?.phases ?? [];
      const phaseMenuItems = phases.map(phase => {
        return {
          to: {name: `phase`, params: {dynamicString: '', slug: phase.slug}},
          title: phase.title
        }
      });
      return [
        {dropdown: false, slug: "/", title: "links.overview"},
        {dropdown: false, slug: "/our-journey", title: "links.journey"},
        {
          dropdown: true,
          slug: 'phase',
          title: "links.phase",
          items: phaseMenuItems,
        },
        {dropdown: false, slug: "/content", title: "links.content"},
      ]
    },
  },
  methods: {
    async loadPhases() {
      const key = `phases${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/phases/all?locale=${this.$i18n.locale}`;
      this.$store.commit('cacheRequest', {key, url});
    },
    toggleDropdown(slug) {
      this.dropdown[slug] = !this.dropdown[slug];
    }
  },
  created() {
    this.loadPhases();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPhases();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-top {
  max-width: 100%;
  height: 100%;
  padding: 8px 0;
  box-shadow: 0 1px 0 #303030;

  .top-nav {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);

    .menu {
      li {
        display: inline-block;
        position: relative;
        padding-right: 24px;

        a {
          &.router-link-exact-active.router-link-active,
          &:hover {
            color: white;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.header-bottom {
  padding: 14px 0;

  .holder {
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .main-logo--wrap {
      align-items: center;
    }

    .centered-logo--wrap {
      align-items: center;
      justify-content: center;
    }

    .site-nav {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      font-size: 1rem;
      font-weight: 500;
      color: white;
      line-height: 24px;
      z-index: 10;

      .menu {
        li {
          display: inline-block;
          position: relative;
          padding-right: 24px;

          a {
            &.router-link-exact-active.router-link-active,
            &:hover {
              color: #a4aaae;
            }
          }

          .dropdown {
            margin-right: 0.3em;
            @include tablet-up {
              position: relative;
            }
            cursor: pointer;

            p {
              color: white;

              &:hover {
                color: #a4aaae;
              }

              .chevron {
                &:before {
                  border-style: solid;
                  border-width: 0.125em 0.125em 0 0;
                  content: '';
                  display: inline-block;
                  height: 0.45em;
                  left: 0.3em;
                  position: relative;
                  vertical-align: middle;
                  width: 0.45em;
                }

                &.top:before {
                  transform: rotate(315deg);
                  top: 0.15em;
                }

                &.bottom:before {
                  transform: rotate(135deg);
                  top: -0.15em;
                }
              }
            }

            &.active {
              .dropdown-items {
                display: flex;
              }
            }

            .dropdown-items {
              position: absolute;
              flex-flow: column;
              justify-content: start;
              width: 100%;
              top: 63px;
              right: 0;
              padding: 24px 0;
              background: #fff;
              z-index: 1;
              @include tablet-up {
                width: 320px;
                top: 53px;
              }

              &:before {
                display: block;
                content: "";
                background-image: url("~@/assets/img/icons/arrow.png");
                position: absolute;
                top: -6px;
                right: 20px;
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                @include tablet-up {
                  top: -8px;
                  right: 5px;
                }
              }

              a {
                @include font($size: 16px, $color: #000, $weight: 500);
                text-decoration: none;
                padding: 8px 16px;

                &:hover {
                  background-color: #DEE0DD;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
