<template>
  <article class="column-card">
    <router-link
      class="card-img-top"
      :to="{ name: `phase`, params: { dynamicString: '', slug: slug } }">
      <div class="image-overlay"></div>
      <div class="card-img-top">
        <img :src="image" alt="Phase image">
      </div>
    </router-link>
    <router-link
      :to="{ name: `phase`, params: { dynamicString: '', slug: slug } }">
      <h2 class="card--title" v-html="hyphenatedTitle"></h2>
    </router-link>
  </article>
</template>

<script>

export default {
  props: ["title", "slug", "image"],
  data() {
    return {};
  },
  computed: {
    hyphenatedTitle() {
      return this.title.split('').join('&shy;');
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.column-card {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  padding: 0;
  flex: 12.5%;
  max-width: 12.5%;

  @media (max-width: 1200px) {
    flex: 22.5%;
    max-width: 22.5%;
  }

  @media (max-width: 1025px) {
    flex: 30%;
    max-width: 33%;
  }

  @include mobile {
    flex: 33%;
    max-width: 47.5%;
  }

  .card-img-top {
    position: relative;
    display: flex;
    max-width: 100px;

    .image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      object-fit: cover;
      background: rgba(0, 0, 0, .5);
      transition: background-color .1s ease-in;

      &:hover {
        background: none;
      }
    }

    img {
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .card--title {
    line-height: 28px;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    color: white;
    padding-top: 0.5em;
    z-index: 10;
    white-space: normal;
    word-break: break-all;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    &:hover {
      color: #a4aaae;
    }
  }
}
</style>
