import Vue from "vue";

import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueGlide from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import { Plugin } from "vue-responsive-video-background-player";
Vue.use(Plugin);

import VueCookies from "vue-cookies";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/i18n";

Vue.use(VueCookies);

Vue.use(BootstrapVue);
Vue.use(VueGlide);
Vue.use(require("vue-moment"));

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
