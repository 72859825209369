import axios from "axios";

// Request interceptor for API calls
axios.interceptors.request.use(
  (config) => {
    const accessToken = $cookies.get("access_token");
    config.headers.common.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const accessToken = response.data.access_token;
    if (accessToken) {
      $cookies.set("access_token", accessToken);
    }
    return response;
  },
  async function(error) {
    return Promise.reject(error);
  }
);
export default axios;
